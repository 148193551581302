<template>
  <div class="contact-page">
		<div class="inner-page">
      <div class="content-container">
        <div class="indent fixed-width mb-5">
					
          <h1><span id="contact-title" class="txtan">404 Error</span><span id="contact-title-2" class="txtan light">Page not found</span></h1>
					<p class="txtan" id="contact-bread">The page you are looking for could not be found...</p>
          <router-link to="/" class="btn icon link">Go to home page</router-link>
				</div>
      </div>
    </div>
    <div class="page-background" id="background-video">
			<video class="show fadeInAnimation" autoplay playsinline muted loop poster="../assets/videos/bg3.jpg">
				<source src="../assets/videos/bg3.webm" type="video/webm">
				<source src="../assets/videos/bg3.mp4" type="video/mp4">
			</video>
		</div>
  </div>
</template>

<script>

  export default {
    name: 'Contact',
    data() {
      return {
        loading: true,
        loadingGps: true,
        videoReady: false,
        messageSending: false,
        messageFrom: '',
        message: '',
        errorMessage: '',
        successMessage: '',
        statusMessage: '',
        gpsPosition: {
          city: 'loading...',
          country: 'loading...'
        },
        localTime: 'Loading data...'
      };
    },
    mounted(){
      this.$nextTick(function () {
        this.$store.commit('setLoading', false);
				this.textAnimation();
      });
    },
    methods: {
			textAnimation() {
				

				var timeline = [
					{
						id: 'contact-title',
						start: 300,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'contact-title-2',
						start: 450,
						style: 'character',
						class: 'fadein',
						segementAnimationDelay: 30,
						segementAnimationDuration: 1500,
					},
					{
						id: 'contact-bread',
						start: 750,
						style: 'word',
						class: 'fadein',
						segementAnimationDelay: 8,
						segementAnimationDuration: 3000,
					},
				];
				
				
				timeline.forEach(function(item) {

					if (item.style == 'character') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/.{1}/g);
						
						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == ' ') ? '&nbsp' : letters[i];

							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
						}

						elem.innerHTML = html;
					}

					if (item.style == 'word') {
						
						let elem = document.getElementById(item.id);
						let letters = elem.textContent.match(/\S*/g);

						let html = '';
						for (let i = 0; i < letters.length; i++) { 
							const startDelay = item.start;
							const letterDelay = (i * item.segementAnimationDelay);
							const animationDuration = item.segementAnimationDuration;
							const delay = startDelay + letterDelay;

							let text = (letters[i] == '') ? '' : letters[i];

              if (text != ''){
                html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '&nbsp;</span>'
              }

						}

						elem.innerHTML = html;
					}

					if (item.style == 'all') {

						let txtanElems = document.getElementById(item.id);
						let text = txtanElems.textContent;

						const startDelay = item.start;
						const letterDelay = item.segementAnimationDelay;
						const animationDuration = item.segementAnimationDuration;

						const delay = startDelay + letterDelay;

						txtanElems.innerHTML = '<span class="letter ' + item.class + '" style="animation-delay:' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span> '; 
					}
					

				});
			},
    }
  }

</script>

<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

</style>
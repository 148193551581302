<template>
	<div class="investments-page">
		<div class="inner-page" @scroll="pageScroll($event)">
			<div class="content-container">
				<div class="indent fixed-width mb-5">
					
					<h1>
						<span id="investments-title" class="txtan">Investments </span><span id="investments-title-2" class="txtan light">&amp; Projects</span>
					</h1>
					<p class="txtan" id="investments-bread">Do you need help with your next project or have an investment opportunity? Please reach out to schedule a discovery call or request more information.</p>

					<h3 class="hr"><span>Current</span></h3>

					<table>
						<thead>
							<tr>
								<th>Company</th>
								<th>Period</th>
								<th>Role</th>
								<th class="right">Status</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in data.filter((item) => item.attributes.active == true)" :key="index">
								<td>{{item.attributes.companyName}}</td>
								<td>{{item.attributes.period}}</td>
								<td>{{item.attributes.role}}</td>
								<td class="right">{{item.attributes.status}}</td>
							</tr>
						</tbody>
					</table> 

					<h3 class="hr mt-4"><span>Past</span></h3>

					<table>
						<thead>
							<tr>
								<th>Company</th>
								<th>Period</th>
								<th>Role</th>
								<th class="right">Status</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in data.filter((item) => item.attributes.active != true)" :key="index">
								<td>{{item.attributes.companyName}}</td>
								<td>{{item.attributes.period}}</td>
								<td>{{item.attributes.role}}</td>
								<td class="right">{{item.attributes.status}}</td>
							</tr>
						</tbody>
					</table> 

				</div>
			</div>
		</div>
		<div class="page-background" id="background-video">
			<video class="show fadeInAnimation" autoplay playsinline muted loop @loadeddata="videoLoaded()" poster="../assets/videos/bg3.jpg">
				<source src="../assets/videos/bg3.webm" type="video/webm">
				<source src="../assets/videos/bg3.mp4" type="video/mp4">
			</video>
		</div>
	</div>
</template>

<script>

import axios from 'axios';

export default {
	name: "Investments",
	data() {
		return {
			loading: true,
			videoReady: false,
			data: []
		};
	},
	mounted(){
		this.$nextTick(function () {
		this.$store.commit('setLoading', false);
			
			this.textAnimation();

			// Get GPS Position
			axios.get(this.$store.state.app.apiUrl + '/investments-and-projects')
				.then(response => {
					this.$store.commit('setLoading', false);
					this.data = response.data.data;
			});
		});
	},
	methods: {
		videoLoaded() {
			this.videoReady = true;
		},
		textAnimation() {
			

			var timeline = [
				{
					id: 'investments-title',
					start: 300,
					style: 'character',
					class: 'fadein',
					segementAnimationDelay: 30,
					segementAnimationDuration: 1500,
				},
				{
					id: 'investments-title-2',
					start: 450,
					style: 'character',
					class: 'fadein',
					segementAnimationDelay: 30,
					segementAnimationDuration: 1500,
				},
				{
					id: 'investments-bread',
					start: 750,
					style: 'word',
					class: 'fadein',
					segementAnimationDelay: 8,
					segementAnimationDuration: 3000,
				},
			];
			
			
			timeline.forEach(function(item) {

				if (item.style == 'character') {
					
					let elem = document.getElementById(item.id);
					let letters = elem.textContent.match(/.{1}/g);
					
					let html = '';
					for (let i = 0; i < letters.length; i++) { 
						const startDelay = item.start;
						const letterDelay = (i * item.segementAnimationDelay);
						const animationDuration = item.segementAnimationDuration;
						const delay = startDelay + letterDelay;

						let text = (letters[i] == ' ') ? '&nbsp' : letters[i];

						html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span>'
					}

					elem.innerHTML = html;
				}

				if (item.style == 'word') {
					
					let elem = document.getElementById(item.id);
					let letters = elem.textContent.match(/\S*/g);

					let html = '';
					for (let i = 0; i < letters.length; i++) { 
						const startDelay = item.start;
						const letterDelay = (i * item.segementAnimationDelay);
						const animationDuration = item.segementAnimationDuration;
						const delay = startDelay + letterDelay;

						let text = (letters[i] == '') ? '' : letters[i];

						if (text != ''){
							html += '<span class="letter ' + item.class + '" style="animation-delay: ' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '&nbsp;</span>'
						}

					}

					elem.innerHTML = html;
				}

				if (item.style == 'all') {

					let txtanElems = document.getElementById(item.id);
					let text = txtanElems.textContent;

					const startDelay = item.start;
					const letterDelay = item.segementAnimationDelay;
					const animationDuration = item.segementAnimationDuration;

					const delay = startDelay + letterDelay;

					txtanElems.innerHTML = '<span class="letter ' + item.class + '" style="animation-delay:' + delay + 'ms; animation-duration: ' + animationDuration + 'ms">' + text + '</span> '; 
				}
				

			});
		},
		pageScroll(event){
			
			const yScrollPos = event.target.scrollTop
			
			const yScrollStart = 50;
			const yScrollDistance = 500;

			const yScroll = (yScrollPos < yScrollStart) ? 0 : (yScrollPos - yScrollStart);
			const yScrollPct = (yScroll / yScrollDistance > 1) ? 1 : (yScroll / yScrollDistance).toFixed(2);
			
			if (this.pageScrollPos != 1 - yScrollPct){
				const opacity = (1 - yScrollPct).toFixed(2);
				this.pageScrollPos = opacity;
				const videoElem = document.getElementById('background-video');
				videoElem.style.opacity = opacity;
			}

		},
	}
};
</script>

<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

	.investments-page {
		
		.inner-page {

			table {
				
				border-collapse: collapse;
				width: 100%;
				
				th, td {
					border: 2px solid $gray-700;
					color: $gray-200;
					font-weight: normal;
					
					&.right {
						text-align: right;
					}
				}

				th {
					background-color: $gray-800;
					text-align:left;
					padding: 5px 12px;
				}

				td {
					text-align:left;
					padding: 12px 12px;
				}

			}
			
			h3.hr {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;
				align-content: stretch;
				align-items: center;
				font-size: $font-size-large;
				margin: 0px;
				margin-bottom: 24px;
				letter-spacing: $font-size-large * $letterspacing-multiplier;

				span {
					flex: 0 1 auto;
				}

				&::after {
					flex: 1 0 auto;
					background: $gray-600;
					height: 2px;
					margin-left: 20px;
					content: "";
				}

				&.mt-4 {
					margin-top:56px;
				}
			}

			.content-container .fixed-width {
				max-width: 1000px !important;
			}

		}

	}

</style>